<template>
  <div class="content_wrap main_content_bg order row">
    <div class="content clearfix col-12">
      <div v-if="cart" id="cart_content">
        <div id="ajax_content" class="">
          <div v-if="isLoaded && cart.quantity" class="row cart-wrapper">
            <div class="clearfix col-12">
              <h3 class="h1 float_l">Acesta este cosul tau de cumparaturi</h3>
            </div>
            <div class="produse-wrapper col-lg-9 col-md-8 col-12">
              <div class="bg">
                <cart-product-line
                  v-for="(item, index) in cart.positions"
                  :key="index + item.product.id"
                  :id="index + item.product.id"
                  :item="item"
                  :index="index"
                />
              </div>

              <div class="cart-actions row d-none d-md-block">
                <div class="offset-lg-7 col-lg-5 col-md-12 col-12">
                  <button @click="setScrollTo" class="btn btn-back">
                    Continua cumparaturile
                  </button>
                </div>
              </div>
            </div>

            <div class="col-lg-3 col-md-4 col-12 fixed_widget">
              <summar />
            </div>
          </div>
          <div v-if="isLoaded && cart.length === 0" class="col-12">
            <h1 class="empty-cart-title">Coșul tău de cumpărături este gol.</h1>
            <div class="empty-cart-p">
              Pentru a adăuga produse în coș te rugăm să te întorci în magazin.
            </div>
            <div class="return-button" style="">
              <router-link :to="{ name: 'Home' }" id="homecategs-cart"
                >Înapoi la produse</router-link
              >
            </div>
            <div class="empty-cart-p-bottom">
              Iar dacă nu ai găsit ce căutai, <br />
              ne poți contacta la <a href="tel:0377101500">0377101500</a>.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Summar from "@/components/cart/Summar.vue";
import CartProductLine from "@/components/cart/CartProductLine.vue";
import { mapGetters, mapActions } from "vuex";
import { generalMixin } from "@/mixins/generalMixin";

export default {
  name: "CartLayout",
  props: ["cart", "isLoaded"],
  components: {
    Summar,
    CartProductLine,
  },
  beforeCreate() {
    // this.$store.dispatch("shop/getCart");
  },
  mixins: [generalMixin],
  methods: {
    ...mapActions("shop", ["updateCart"]),
  },
  computed: {
    ...mapGetters("shop", ["products", "cartProducts"]),
  },
  mounted() {
    // console.log(this.cart);
  },
};
</script>

<style scoped>
/* .cart-wrapper {
  width: 100%;
} */

#cart_content p {
  font-size: 14px;
}

.empty-cart-title {
  font-size: 50px;
  margin: 170px auto 50px auto;
  text-align: center;
  font-family: "Raleway Bold";
}

.empty-cart-p {
  font-size: 16px;
  /* font-weight: bold; */
  font-family: "Raleway Bold";
  margin: auto auto 35px auto;
  width: 270px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.27px;
}

.return-button {
  font-size: 16px;
  font-weight: bold;
  margin: auto auto 50px auto;
  text-align: center;
  line-height: 55px;
}

.return-button a {
  color: #fff;
  text-align: center;
  padding: 18px 59px 18px 59px;
  background-color: #990001;
  border-radius: 28px;
  cursor: pointer;
  font-family: "Raleway Bold";
  letter-spacing: 0.34px;
}

.empty-cart-p-bottom {
  font-size: 16px;
  margin: auto auto 160px auto;
  width: 260px;
  text-align: center;
  font-family: "Raleway Bold";
  line-height: 26px;
  letter-spacing: 0.27px;
}

@media (max-width: 1200px) {
  .empty-cart-title {
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  .empty-cart-title {
    font-size: 35px;
  }
}

@media (max-width: 768px) {
  .empty-cart-title {
    font-size: 30px;
  }

  .produse-wrapper {
    padding: 0;
  }
}

@media (max-width: 576px) {
  .empty-cart-title {
    /* margin-top: 20px !important; */
    font-size: 25px;
  }
}
</style>
