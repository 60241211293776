<template>
  <div class="order-item row cart__item">
    <loading :active="spinner && !removeSpinner" :is-full-page="false"> </loading>

    <loading
      :active="spinner && removeSpinner"
      :is-full-page="false"
      :background-color="'#999494'"
    >
    </loading>
    <div class="col-xl-5 col-lg-4 col-md-12 col-12 pic-title pr-0" :style="item?.product?.layout_property[0]?.value == 'custom-hoch' ? 'align-items:end' : ''">
      <div class="img-wrapper">
        <img
          class="cart__img"
          :src="item.product.thumbnail"
          alt="IMAGE"
          title="IMAGE"
          loading="lazy"
        />
      </div>
      <div class="item-name">
        <router-link
          :to="{ name: 'productpage', params: { slug: item.product.slug } }"
          class="cart__img-wrap d-block"
        >
          {{ productName }}
        </router-link>
        <brief-order-details :class="'in-cart'" v-if='item?.product?.layout_property[0]?.value == "custom-hoch"' :slug="item.product.slug" :productname="productName" :steps="item.property['custom-options']" :price="{total: item.property['custom-price'], total_promo: 0}"  />
      </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 pl-0 pr-0">
      <span class="yes-title cart-label"
        >Pret unitar <br class="d-none d-sm-block" />
        <span class="without-tva">(fara TVA)</span>:
      </span>
      <span class="ron-price"
        >{{ item.price_per_unit_without_tax }} {{ item.currency }}</span
      >
      <span class="valuta-price"
        >( {{ priceInEuro(item.price_per_unit_without_tax_value) }} &euro; )</span
      >
    </div>
    <div class="pret-total col-xl-2 col-lg-2 col-md-4 col-sm-4 col-6 pl-0 pr-0">
      <span class="yes-title cart-label"
        >Pret total <br class="d-none d-sm-block" />
        <span class="without-tva">(cu TVA)</span>:
      </span>
      <span class="ron-price"> {{ item.total }} {{ item.currency }}</span>
      <span class="valuta-price">( {{ priceInEuro(item.total_value) }} &euro;)</span>
    </div>
    <div class="item-cantitate col-xl-2 col-lg-3 col-md-4 col-sm-4 col-12 pl-0 pr-0">
      <span class="d-block d-lg-none d-xl-none yes-title cart-label">Cantitatea: </span>
      <div class="row">
        <change-quantity-cart
          :key="index"
          :value="item.quantity"
          :offerId="item.offer.id"
          :possitionId="item.id"
          @changeSpinner="changeSpinnerState"
          class="col-lg-12 col-md-12 col-sm-12"
        />
      </div>
    </div>

    <div class="remove-item-cart col-xl-1 col-lg-1 col-md-3 col-sm-3 col-3 pl-0 pr-0">
      <a @click="removeFromCart">
        <font-awesome-icon icon="times" /><span
          class="yes-title cart-label"
          aria-label="Delete"
          data-id="3"
          >Sterge</span
        ></a
      >
    </div>
  </div>
</template>

<script>
import changeQuantityCart from "@/components/product_page/changeQuantityCart.vue";
import BriefOrderDetails from "@/components/product_page/custom_hoch/BriefOrderDetails.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { shopMixin } from "@/mixins/shopMixin";

import { mapActions } from "vuex";

export default {
  name: "CartProductLine",
  props: ["item", "index"],
  components: {
    changeQuantityCart,
    Loading,
    BriefOrderDetails,
  },
  data() {
    return {
      quantity: 1,
      spinner: false,
      removeSpinner: false,
      productName: "",
    };
  },
  created() {
    if (
      Object.prototype.hasOwnProperty.call(this.item, "property") &&
      typeof this.item.property === "object" &&
      Object.prototype.hasOwnProperty.call(this.item.property, "custom-name")
    ) {
      this.productName = this.item.property["custom-name"];
    } else {
      this.productName = this.item.product.name;
    }
  },
  methods: {
    ...mapActions("shop", ["removeProductFromCart"]),
    removeFromCart() {
      // console.log(this.item);
      this.spinner = true;
      this.removeSpinner = true;
      const payload = {
        offer_id: this.item.offer.id,
        position_id: this.item.id,
      };
      this.$store.dispatch("shop/removeProductFromCart", payload).then(() => {
        // console.log(response);
        let self = this;
        setTimeout(function () {
          self.spinner = false;
          self.removeSpinner = false;
        }, 1000);
      });
    },
    changeSpinnerState(status) {
      // console.log(status);
      this.spinner = status;
    },
  },
  mixins: [shopMixin],
};
</script>

<style scoped>
.remove-item-cart a {
  display: flex;
  align-items: center;
}
</style>
