<template>
  <div class="col-lg-4 col-md-4 col-sm-4 qty_wrapper">
    <div class="add-quantity">
      <span class="minus-quant" @click="substractQty" :disabled="value <= 1"
        ><font-awesome-icon icon="minus"
      /></span>
      <input
        :key="offerId"
        :id="offerId"
        type="text"
        :name="`quantity-prod-${offerId}`"
        v-model="inputValue"
        maxlength="3"
        class="quantity-input"
        v-on:keyup="inputPress"
      />
      <!-- <input type="hidden" name="update" value="1" /> -->
      <span class="plus-quant" @click="addQty"><font-awesome-icon icon="plus" /></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChangeQuantity",
  data() {
    return {
      inputValue: this.value,
      id: null,
      quantityLimit: 120,
    };
  },
  // props: {
  //   quantity: Number,
  //   value: Number,
  //   offerId: Number,
  // },

  props: ["quantity", "value", "offerId", "possitionId"],
  emits: ["changeSpinner"],
  computed: {
    qtyInput() {
      // return document.querySelector(".quantity-input");
      return this.inputValue;
    },
  },
  methods: {
    addQty() {
      this.$emit("changeSpinner", true);
      this.$store.dispatch("shop/actionSpinner", true);
      var qty =
        this.inputValue < this.quantityLimit ? this.inputValue++ : this.quantityLimit;
      // this.$emit('changeQty', (qty +1) )
      this.$store
        .dispatch("shop/updateCart", {
          id: this.possitionId,
          offer_id: this.offerId,
          quantity: qty + 1,
        })
        .then(() => {
          let self = this;
          setTimeout(function () {
            self.$emit("changeSpinner", false);
            self.$store.dispatch("shop/actionSpinner", false);
          }, 1000);
        });
      // console.log(this.offerId, 'offeridul');
      return qty;
    },

    substractQty() {
      this.$emit("changeSpinner", true);
      this.$store.dispatch("shop/actionSpinner", true);
      var qty = this.inputValue > 1 ? this.inputValue-- : 0;
      // this.$emit('changeQty', (qty -1) )
      this.$store
        .dispatch("shop/updateCart", {
          id: this.possitionId,
          offer_id: this.offerId,
          quantity: qty - 1,
        })
        .then(() => {
          let self = this;
          setTimeout(function () {
            self.$emit("changeSpinner", false);
            self.$store.dispatch("shop/actionSpinner", false);
          }, 1000);
        });
      return qty;
    },

    inputPress() {
      this.$emit("changeSpinner", true);
      this.$store.dispatch("shop/actionSpinner", true);
      if (this.inputValue < 0) {
        this.inputValue = 1;
      } else if (this.inputValue > this.quantityLimit) {
        this.inputValue = this.quantityLimit;
      }

      var qty = this.qtyInput;
      // console.log(qty, "qty2");
      this.$store
        .dispatch("shop/updateCart", {
          id: this.possitionId,
          offer_id: this.offerId,
          quantity: qty,
        })
        .then(() => {
          let self = this;
          setTimeout(function () {
            self.$emit("changeSpinner", false);
            self.$store.dispatch("shop/actionSpinner", false);
          }, 1000);
        });
    },
  },
};
</script>

<style scoped>
span[disabled="true"] {
  pointer-events: none;
  background-color: #ddd;
}
</style>
