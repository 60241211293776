<template>
  <div class="cart-page">
    <page-header :item="header_image" />
    <div class="container">
      <cart-layout :cart="cart" :isLoaded="isLoaded" />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/page_header/PageHeader.vue";
import CartLayout from "@/components/cart/CartLayout.vue";

export default {
  name: "Cart",
  // data() {
  //   return {
  //     isLoaded: false,
  //   }
  // },
  components: {
    "page-header": PageHeader,
    "cart-layout": CartLayout,
  },
  computed: {
    cart() {
      return this.$store.getters["shop/cartData"];
    },
    header_image() {
      return {
        path: this.$store.getters["seo/seo_image"],
      };
    },
    isLoaded() {
      return this.$store.getters["shop/cartIsLoaded"];
    },
  },
  mounted() {
    this.$store.commit("seo/setParams", {
      seo_image: require("@/assets/images/cart_view.jpg"),
      seo_title: "Seminee - Cos de cumparaturi",
      seo_description: "",
      seo_keywords: "",
    });
    this.$store.dispatch("shop/getCart");
  },
  created() {
    // this.$store.dispatch("shop/getCart");
  },
};
</script>

<style scoped>
@import "../../assets/css/cart.css";
@import "../../assets/css/product.css";
</style>
